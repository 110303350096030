/** @jsxImportSource @emotion/react */

import PropTypes from 'prop-types';

// Components
import Select, { components } from 'react-select';
import { StyledInputCSSObject } from 'components/htmlElements/Fields/StyledInput';
import H5 from 'components/htmlElements/H5';

// Styling
import { misc, colors } from 'styles/utilities';

import * as styles from './Select.styles';

const Option = ({ innerRef, innerProps, isSelected, isDisabled, ...props }) => (
  <div css={styles.option({ isSelected, isDisabled })} {...innerProps} ref={innerRef}>
    {props.children}
  </div>
);

Option.propTypes = {
  innerRef: PropTypes.any.isRequired,
  innerProps: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  isSelected: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired
};

const ControlComponent = (props) => (
  <H5 as="div" gtWalsheim bold style={{ margin: 0 }}>
    <components.Control {...props} />
  </H5>
);

const customStyles = {
  control: (provided) => ({
    ...provided,
    ...StyledInputCSSObject,
    backgroundColor: `${colors.lightBlue}`,
    border: `2px solid ${colors.lightBlue}`,
    flexWrap: 'nowrap',
    cursor: 'pointer'
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    minWidth: state.selectProps.size || '150px',
    padding: `11.5px ${misc.input.paddingHorizontal}`,
    lineHeight: 1.6
  }),
  placeholder: (provided) => ({
    ...provided,
    color: colors.navyBlue
  }),
  singleValue: (provided) => ({
    ...provided,
    color: colors.navyBlue
  }),
  menu: (provided) => ({
    ...provided,
    boxShadow: `${colors.blackTransparent10} 0 0 5px 0`,
    border: 'none',
    borderRadius: misc.smallBorderRadius,
    marginTop: '5px',
    marginBottom: '0'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  })
};

const SelectComponent = (props) => (
  <Select
    {...props}
    menuPortalTarget={document.body}
    styles={customStyles}
    components={{ Option, Control: ControlComponent }}
  />
);

export default SelectComponent;
