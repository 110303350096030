/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';
import facepaint from 'facepaint';
import hexToRgba from 'hex-to-rgba';

import { colors, misc, mixins, typography } from 'styles/utilities';

const breakPoints = facepaint([mixins.respondTo(misc.viewport['d-vp'])]);

export const option = ({ isSelected, isDisabled }) => css`
  margin: 0;
  padding: ${misc.input.paddingVertical} ${misc.input.paddingHorizontal};
  cursor: pointer;
  min-width: 100%;
  &:hover {
    background-color: ${hexToRgba(colors.lighterBlue, '0.1')};
  }

  font-family: ${typography.fonts.gtWalsheim.fontFamily};
  font-weight: ${typography.fonts.gtWalsheim.fontWeight.medium};
  color: ${colors.navyBlue};
  ${breakPoints({
    fontSize: ['14px', typography.baseSizes.eta]
  })};

  ${isSelected &&
  css`
    background-color: ${hexToRgba(colors.lighterBlue, '0.2')};
  `}

  ${isDisabled &&
  css`
    color: ${colors.grey};
    cursor: not-allowed;
  `}
`;

export const selectContainer = css`
  min-width: 250px;
`;
