// This file should contain all global styles
import { css } from '@emotion/react';
import { colors, misc, mixins } from 'styles/utilities';

import fonts from 'styles/helpers/fonts';
import reboot from './bootstrap-reboot';
import FormFieldResets from './FormFieldResets';

// Import additional global styles

const globalStyles = css`
  ${reboot}
  ${fonts}
  html, body {
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: ${colors.mediumGrey};
  }
  body,
  body .default-font-wrapper {
    color: ${colors.black};
    margin: 0;
    padding: 0;
    width: 100%;
  }
  img {
    vertical-align: bottom;
    height: auto;
  }

  video {
    vertical-align: bottom;
  }

  svg {
    vertical-align: bottom;
  }
  ul,
  ol {
    &:not(.ck *) {
      /* Avoid styling ck editor */
      padding: 0;
      margin: 0;
    }
    &.styled {
      padding-left: 1.5em;
    }
    &.unstyled {
      list-style: none;
    }
  }
  sup {
    font-size: 0.8em;
    top: 0;
    vertical-align: super;
  }

  .is-relative {
    position: relative;
  }

  .sidebar-spacer {
    padding-left: 82px;
  }

  .noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
  }

  ${mixins.respondToMax(misc.viewport['c-vp'])} {
    .container {
      &:not(.no-push-container) {
        padding-left: ${misc.gutterSpacings['max-c-vp']};
        padding-right: ${misc.gutterSpacings['max-c-vp']};
      }
    }
    .no-push-container {
      padding-left: ${parseInt(misc.rowColumnSpacing) * 2}px;
      padding-right: ${parseInt(misc.rowColumnSpacing) * 2}px;
    }
    .row {
      margin-left: ${parseInt(misc.rowColumnSpacing) * -1}px;
      margin-right: ${parseInt(misc.rowColumnSpacing) * -1}px;
    }
    [class*='col-'] {
      padding-left: ${misc.rowColumnSpacing};
      padding-right: ${misc.rowColumnSpacing};
    }
    .container.edge-container {
      padding-left: 0;
      padding-right: 0;
      > .row {
        margin-left: 0;
        margin-right: 0;
        padding-left: ${misc.rowColumnSpacing};
        padding-right: ${misc.rowColumnSpacing};
      }
    }
  }

  ${FormFieldResets}

  p {
    margin: ${misc.defaultTextMargin};
  }

  .border-default {
    border: ${misc.border.default};
  }
  .border-radius-default {
    border-radius: ${misc.largeBorderRadius};
  }
  .br-t {
    border-top: ${misc.border.default};
  }
  .br-r {
    border-right: ${misc.border.default};
  }
  .br-b {
    border-bottom: ${misc.border.default};
  }
  .br-l {
    border-left: ${misc.border.default};
  }

  .card-default {
    background-color: ${colors.white};
    border-radius: ${misc.largeBorderRadius};
    overflow: hidden;
    border: ${misc.border.default};
    &.no-border {
      border: none;
    }
  }
  .card-spacing-default {
    padding: ${misc.cardSpacing.default};
  }
  .card-spacing-small {
    padding: ${misc.cardSpacing.small};
  }

  .ck.ck-editor {
    overflow: hidden;
  }

  .drag-icon-wrapper {
    cursor: move;
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const globals = {
  ...globalStyles
};
