/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';
import { colors, misc } from 'styles/utilities';

export const sectionCardCol = css`
  margin-bottom: 30px;
`;

export const sectionCard = css`
  background: ${colors.white};
  border-radius: ${misc.mediumBorderRadius};
  height: 100%;
`;

export const selectWrapper = css`
  width: 100%;
`;

export const levelSelectContainer = css`
  display: flex;
  gap: 20px;
  align-items: center;
`;

export const modalWrapper = css`
  .modal-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .modal-contents {
    position: relative;
  }

  .modal-close {
    display: none;
  }
`;

export const levelBand = css`
  padding: 15px;
  border-radius: ${misc.mediumBorderRadius};
  background: ${colors.lightBlue};
`;
