/** @jsxImportSource @emotion/react */

import {
  Redirect
  // UnstyledLink
} from 'components/Router';
import {
  useGetTestSettings
  // useMigrateTest
} from 'api/test';
import PageLoader from 'components/PageLoader';
import { ROUTE_PATHS } from 'globals/constants';
import PropTypes from 'prop-types';
import {
  //  colors,
  spacer
} from 'styles/utilities';
import H3 from 'components/htmlElements/H3';
import H4 from 'components/htmlElements/H4';

// import Button from 'components/htmlElements/Button';
import P from 'components/htmlElements/P';

// import Modal, { useModal } from 'components/Modal/Modal';
import { Col, Row } from 'components/Grid/Grid';
import * as styles from '../page.styles';

const SectionCard = ({ section }) => {
  const { subsections } = section;

  return (
    <div css={[styles.sectionCard, spacer.padBT20, spacer.padLR20]}>
      <H4 css={spacer.mrB15} className="mt-0">
        {section.name}
      </H4>

      <div css={styles.levelSelectContainer}>
        {subsections &&
          subsections?.map((subsection) => (
            <div key={subsection._id} css={styles.selectWrapper}>
              {/* <P gtWalsheim medium css={spacer.mrB10}>
                Level {index + 1}
              </P> */}

              <div css={styles.levelBand}>
                <H4 bold gtWalsheim css={[spacer.mrB10, spacer.mrT0]} color="navyBlue">
                  {subsection.name}
                </H4>

                <P css={spacer.mrB10}>
                  Total Questions: <strong>{subsection.totalQuestions} Questions</strong>
                </P>
                <P css={spacer.mrB10}>
                  Total Marks: <strong>{subsection.totalMarks} Marks</strong>
                </P>
                <P className="mb-0">
                  Time Limit: <strong>{subsection.settings.timeLimit} Minutes</strong>
                </P>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

SectionCard.propTypes = {
  section: PropTypes.object.isRequired
};

const TestContainer = ({ testId }) => {
  // const { isOpen, openModal, closeModal } = useModal();
  const { data: testData, status: testStatus, error: testError } = useGetTestSettings(testId);
  // const { migrate, isLoading, status: migrationStatus } = useMigrateTest();

  if (testError) {
    return <Redirect to={ROUTE_PATHS.notFound} noThrow />;
  }

  if (testStatus !== 'success') {
    return <PageLoader isFetching isRelative atPageHeight />;
  }

  const test = testData?.data;

  // const sectionsData = test?.sections?.map((section) => ({
  //   sectionId: section._id,
  //   primarySubSectionId: section.subsections[0]?._id,
  //   metaUpdates: {
  //     level1Id: section.subsections[0]?._id,
  //     level2Id: section.subsections[1]?._id,
  //     level3Id: section.subsections[2]?._id
  //   }
  // }));

  // const testMigrationHandler = async () => {
  //   const migrationData = {
  //     testId,
  //     sections: sectionsData
  //   };

  //   // scroll to top
  //   window.scrollTo(0, 0);

  //   await migrate({ testId, payload: migrationData }).finally(() => {
  //     openModal();
  //   });
  // };

  // validate if all sections have been selected, till then disable the save button
  // const isSectionsDataValid =
  //   sectionsData.every((section) => {
  //     const { primarySubSectionId, metaUpdates } = section;
  //     return primarySubSectionId && Object.values(metaUpdates).length === 3;
  //   }) && sectionsData.length === test?.sections?.length;

  // const closeModalHandler = () => {
  //   closeModal();

  //   if (migrationStatus === 'success') {
  //     window.location.reload();
  //   }
  // };

  return (
    <section css={spacer.mrB80}>
      {/* {isLoading && <PageLoader atPageHeight bgColor={colors.blackTransparent50} />} */}
      <H3 css={spacer.mrB20}>{test?.name}</H3>

      <Row>
        {test?.sections?.map((section) => (
          <Col className="col-md-6 col-lg-4" css={styles.sectionCardCol}>
            <SectionCard key={section._id} section={section} />
          </Col>
        ))}
      </Row>

      {/* <div className="d-flex justify-content-end" css={spacer.mrT40}>
        <Button large onClick={testMigrationHandler} disabled={!isSectionsDataValid}>
          Save
        </Button>
      </div> */}

      {/* <Modal toggle={closeModalHandler} isOpen={isOpen} emotionCss={styles.modalWrapper}>
        <div css={[spacer.padLR20, spacer.padT20]}>
          {migrationStatus === 'success' && (
            <div css={spacer.mrB20}>
              <H3>Test has been migrated successfully</H3>

              <P css={spacer.mrB20}>For further modifications go to the test page.</P>
              <UnstyledLink to={`/test/${testId}`}>{test.name}</UnstyledLink>
            </div>
          )}
          {migrationStatus === 'error' && (
            <div css={spacer.mrB20}>
              <H3 color="red">Test migration failed.</H3>

              <P css={spacer.mrB20}>Please try again.</P>
            </div>
          )}

          <div className="d-flex justify-content-end">
            <Button onClick={closeModalHandler}>Close</Button>
          </div>
        </div>
      </Modal> */}
    </section>
  );
};

TestContainer.propTypes = {
  testId: PropTypes.string.isRequired
};

export default TestContainer;
