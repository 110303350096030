/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { findKey } from 'lodash-es';
import arrayMove from 'array-move';
import CreateTestContainer from 'hooks/useCreateTest';
import { useSubSectionSettingsContext } from 'components/SubSectionSettingsForm/SubSectionSettingsContext';

import A from 'components/htmlElements/A';
import SidebarListItem from 'components/SidebarShell/SidebarListItem';
import LoaderOverlay from 'components/LoaderOverlay';

// Constants
import { TEST_STRUCTURE } from 'globals/constants';
import { spacer } from 'styles/utilities';

import { navigate } from 'components/Router';
import useSearchQuery from 'hooks/useSearchQuery';

const SortableItem = SortableElement(({ subSection, sectionId }) => {
  const { setQueryParams, useParseQueryString } = useSearchQuery();
  const queryStringData = useParseQueryString();

  const handleOnClick = () => {
    const queryString = setQueryParams(
      {
        sectionId,
        subsectionId: subSection._id
      },
      true
    );
    navigate(`?${queryString}`);
  };

  return (
    <SidebarListItem
      heading={subSection.name}
      subHeading={`${subSection.totalQuestions} Questions`}
      onClick={handleOnClick}
      isActive={queryStringData.subsectionId === subSection._id}
    />
  );
});

const SortableList = SortableContainer(({ subSections, sectionId }) => (
  <div>
    {subSections.map((subSection, index) => (
      <SortableItem
        key={`item-${subSection._id}`}
        index={index}
        subSection={subSection}
        sectionId={sectionId}
      />
    ))}
  </div>
));

const SidebarSubSections = ({ parentSection }) => {
  const { testSettings, updateSectionsOrder } = CreateTestContainer.useContainer();
  const { addNewSubSection } = useSubSectionSettingsContext();

  const [overlay, toggleOverlay] = useState(false);

  const [subsectionsList, updateSubsectionsList] = useState(parentSection.subsections);
  // useEffect will update the state if any sections are updated
  useEffect(() => {
    updateSubsectionsList(
      testSettings.sections[findKey(testSettings.sections, ['_id', parentSection._id])].subsections
    );
  }, [parentSection._id, testSettings.sections]);

  const updateSectionOrder = async ({ oldIndex, newIndex }) => {
    const updatedSubsectionsList = arrayMove(subsectionsList, oldIndex, newIndex);

    // Optimistic sections update
    updateSubsectionsList(updatedSubsectionsList);

    // Get the parent Section index and update it
    const sectionsList = testSettings.sections;
    const currentSectionIndex = findKey(testSettings.sections, ['_id', parentSection._id]);
    // Replace the sub-sections at the parent section index
    sectionsList[currentSectionIndex].subsections = updatedSubsectionsList;

    toggleOverlay(true);
    const data = await updateSectionsOrder(testSettings, sectionsList);

    // If API call fails, set the sections to order returned by API
    if (data)
      updateSubsectionsList(
        data.sections[findKey(testSettings.sections, ['_id', parentSection._id])].subsections
      );

    toggleOverlay(false);
  };

  // If test is not at `subSection` or subsection array doesn't exist return null
  if (testSettings.questionStructure !== TEST_STRUCTURE.subsections) return null;

  return (
    <div>
      {parentSection.subsections && subsectionsList !== undefined ? (
        <div className="is-relative">
          <SortableList
            useWindowAsScrollContainer
            axis="y"
            lockAxis="y"
            distance={2}
            subSections={subsectionsList}
            onSortEnd={updateSectionOrder}
            sectionId={parentSection._id}
            useDragHandle
          />
          {overlay && <LoaderOverlay />}
        </div>
      ) : (
        ''
      )}
      <div className="text-right" css={[spacer.padR15, spacer.padBT10]}>
        <A
          href="#"
          semiBold
          color="lighterBlue"
          className="m-0"
          onClick={() => addNewSubSection(parentSection._id)}
        >
          + Add subsection
        </A>
      </div>
    </div>
  );
};

SidebarSubSections.propTypes = {
  parentSection: PropTypes.object.isRequired
};

export default SidebarSubSections;
