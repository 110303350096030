/** @jsxImportSource @emotion/react */

import { colors, spacer } from 'styles/utilities';
import { UserInfoContainer, useLogout } from 'api/auth';
import ProfileIcon from 'components/ProfileIcon/ProfileIcon';
import P from 'components/htmlElements/P';
import Icon from 'components/Icon/Icon';
import NavLink from 'components/NavLink';
import A from 'components/htmlElements/A';
import { EB_ORG_ID, ROUTE_PATHS, site } from 'globals/constants';
import { useLocation } from '@reach/router';
import ProfileModal from 'components/ProfileModal';
import { Fragment, useState } from 'react';
import Modal, { useModal } from 'components/Modal/Modal';

import * as styles from './SidebarNav.styles';

const sidebarIcons = ({ orgId }) => [
  { label: 'Home', icon: 'home', location: '/' },
  { label: 'Media', icon: 'layers', location: ROUTE_PATHS.mediaLibrary },
  { label: 'Create Test', icon: 'plus-square', location: ROUTE_PATHS.testCreate },
  { label: 'Create Course', icon: 'plus-square', location: ROUTE_PATHS.courseCreate },
  { label: 'Users', icon: 'users', location: ROUTE_PATHS.users },
  ...(orgId === EB_ORG_ID
    ? [{ label: 'Migrate', icon: 'copy_rounded', location: ROUTE_PATHS.migrate }]
    : [])
];

const SidebarNav = () => {
  const user = UserInfoContainer.useContainer();
  const routeLocation = useLocation();
  const logout = useLogout();
  const { isOpen: isProfileOpen, toggle: toggleProfileModal } = useModal();
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(true);

  return (
    <Fragment>
      <div css={[styles.sidebarWrapper, spacer.padLR20]}>
        <div className="d-flex flex-column main-nav w-100">
          <A
            as={NavLink}
            className="d-flex align-items-center"
            nounderline
            to="/"
            css={[spacer.padBT10, spacer.padL5]}
          >
            <img src="/logo.svg" width="32px" alt={site.name} className="logo" />

            {!isSidebarExpanded && (
              <P color="black" workSans medium css={spacer.mrL10}>
                {site.name}
              </P>
            )}
          </A>

          {sidebarIcons({ orgId: user.orgId }).map(({ label, icon, location }) => (
            <A
              as={NavLink}
              key={label}
              to={location}
              css={[spacer.padBT10, spacer.padLR10]}
              className={`link-wrapper ${routeLocation?.pathname === location ? 'active' : ''}`}
            >
              <Icon icon={icon} size={22} color={colors.navyBlue} />

              {!isSidebarExpanded && (
                <P color="black" workSans medium className="m-0">
                  {label}
                </P>
              )}
            </A>
          ))}
        </div>

        <div className="w-100">
          <A href="#" as="div" css={[spacer.padBT10]} onClick={toggleProfileModal}>
            <ProfileIcon
              type="text"
              firstName={user.name.split(/ (.+)/)[0]}
              lastName={user.name.split(/ (.+)/)[1]}
              image={require('images/logo-emblem.png')}
              size="42px"
            />
          </A>

          <A
            href="#"
            onClick={(e) => {
              e.preventDefault();
              logout();
            }}
          >
            <div className="logout" css={[spacer.padBT10, spacer.padLR10]}>
              <Icon icon="logout" size={22} color={colors.navyBlue} />
              {!isSidebarExpanded && (
                <P color="black" workSans medium className="m-0">
                  Logout
                </P>
              )}
            </div>
          </A>
        </div>

        <div
          className="sidebar-toggle"
          onClick={() => setIsSidebarExpanded((prev) => !prev)}
          onKeyDown={() => {}}
          role="button"
          aria-label="toggle sidebar"
          tabIndex={0}
        >
          <Icon
            icon={isSidebarExpanded ? 'chevron-right' : 'chevron-left'}
            size={22}
            color={colors.navyBlue}
          />
        </div>
      </div>

      <Modal isOpen={isProfileOpen} toggle={toggleProfileModal}>
        <ProfileModal closeModal={toggleProfileModal} userInfo={user && user} />
      </Modal>
    </Fragment>
  );
};

export default SidebarNav;
