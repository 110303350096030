/* eslint-disable import/prefer-default-export */

export const ROUTE_PATHS = {
  login: '/login',
  home: '/',
  productListing: '/products',
  mediaLibrary: '/media-library',
  users: '/users',
  notFound: '/404',

  // Tests
  // tests: '/tests',
  testCreate: '/create-test',
  testDetails: '/test/:testId',
  testSettings: '/test/:testId/settings',
  createQuestions: '/test/:testId/questions',
  previewQuestions: '/test/:testId/preview',
  migrate: '/migrate',

  // Course
  courseCreate: '/create-course',
  courseDetails: '/course/:courseId',
  courseSettings: '/course/:courseId/settings',
  courseLessons: '/course/:courseId/lessons'
};

// Test setting constants
export const TEST_STRUCTURE = {
  test: 'test',
  sections: 'section',
  subsections: 'subSection'
};

export const EXTRA_TIME_TYPES = {
  mins: 'seconds',
  percentage: 'percentage'
};

export const QUESTION_USAGE_TYPE = {
  final: 'final',
  sample: 'sample',
  practice: 'practice',
  note: 'note'
};

// Course constants
export const COURSE_STRUCTURE = {
  course: 'course',
  sections: 'section',
  subsections: 'subSection'
};

const apiHost = `${process.env.TEST_TOOLS_API_DOMAIN}`;

export const api = {
  endpoints: {
    auth: {
      login: `${apiHost}/auth/login`,
      requestResetPassword: `${apiHost}/auth/requestResetPassword`,
      resetPassword: `${apiHost}/auth/resetPassword`,
      authenticateToken: `${apiHost}/validate-jwt-token`
    },
    org: {
      getOrgsNames: `${apiHost}/organization-names`
    },
    tests: {
      get: `${apiHost}/tests`
    },
    test: {
      create: () => `${apiHost}/test`,
      update: (testId) => `${apiHost}/test/${testId}`,
      findOne: (testId) => `${apiHost}/test/${testId}`,
      setCurrentVersion: (testId) => `${apiHost}/set-default-test-version/${testId}`,
      clone: (testId) => `${apiHost}/clone-test/${testId}`,
      migrate: (testId) => `${apiHost}/migrate-cb-questions/${testId}`
    },
    courses: {
      get: `${apiHost}/courses`
    },
    course: {
      create: () => `${apiHost}/course`,
      update: (courseId) => `${apiHost}/course/${courseId}`,
      findOne: (courseId) => `${apiHost}/course/${courseId}`,
      lessons: {
        get: `${apiHost}/lessons`,
        create: `${apiHost}/lesson`,
        update: (lessonId) => `${apiHost}/lesson/${lessonId}`,
        findOne: (lessonId) => `${apiHost}/lesson/${lessonId}`,
        delete: (lessonId) => `${apiHost}/lesson/${lessonId}`,
        reorder: (lessonId) => `${apiHost}/lesson/${lessonId}/reorder`
      }
    },
    questions: {
      get: `${apiHost}/questions`
    },
    question: {
      create: () => `${apiHost}/question`,
      update: (questionId) => `${apiHost}/question/${questionId}`,
      findOne: (questionId) => `${apiHost}/question/${questionId}`,
      delete: (questionId) => `${apiHost}/question/${questionId}`,
      reorder: (questionId) => `${apiHost}/question/${questionId}/reorder`
    },
    media: {
      create: `${apiHost}/media`,
      getAll: `${apiHost}/medias`,
      get: (mediaId) => `${apiHost}/media/${mediaId}`,
      delete: (mediaId) => `${apiHost}/media/${mediaId}`
    },
    users: {
      create: `${apiHost}/user`,
      getAll: `${apiHost}/users`,
      delete: (userId) => `${apiHost}/user/${userId}`,
      update: (userId) => `${apiHost}/user/${userId}`
    }
  }
};

export const site = {
  name: 'Tests Hub',
  url: 'https://testshub.co',
  themeColor: '#2654b4'
};

export const sentryDSN =
  'https://37bf8945a3a04cfba47dc2453fb1cc4a@o508610.ingest.sentry.io/5601362';

export const QUESTIONPAPER_TYPE = [
  { type: 'questionType', val: 'questionType', header: 'Question Type' },
  { type: 'Final', val: 'finalQuestion', header: 'Final Question' },
  { type: 'Sample', val: 'sampleQuestion', header: 'Sample Question' },
  { type: 'Practice', val: 'practiceQuestion', header: 'Practice Question' }
];

export const NANO_ID_ALPHANUMERICS =
  'abcdefghijklmnopqrstuvwxyz0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';

export const EB_ORG_ID = '61ab51aed9607b022446cfa2';

// staging org id
// export const EB_ORG_ID = '6026521bab99dc0015146b0e';
