import React, { Fragment } from 'react';
import { ROUTE_PATHS } from 'globals/constants';

import { Redirect } from 'components/Router';
import GetTestsContainer from 'api/tests';
import GetCoursesContainer from 'api/courses';
import PageLoader from 'components/PageLoader';

import MIgrate from './page';

const MigrateContainer = () => {
  const {
    data: tests,
    error: testsError,
    status: testsStatus,
    fetchNextPage: testsFetchNextPage,
    hasNextPage: testsHasNextPage,
    isFetchingNextPage: testsIsFetchingNextPage
  } = GetTestsContainer.useContainer();

  if (testsError) {
    return <Redirect to={ROUTE_PATHS.notFound} />;
  }

  if (testsStatus !== 'success' || typeof tests === 'undefined') {
    return <PageLoader isFetching isRelative atPageHeight />;
  }

  return (
    <Fragment>
      <MIgrate
        tests={tests.data}
        testsStatus={testsStatus}
        testsFetchNextPage={testsFetchNextPage}
        testsHasNextPage={testsHasNextPage}
        testsIsFetchingNextPage={testsIsFetchingNextPage}
      />
    </Fragment>
  );
};

export default () => (
  <GetTestsContainer.Provider initialState={{}}>
    <GetCoursesContainer.Provider>
      <MigrateContainer />
    </GetCoursesContainer.Provider>
  </GetTestsContainer.Provider>
);
