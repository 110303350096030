import React from 'react';
import { Router } from 'components/Router';
import { ROUTE_PATHS } from 'globals/constants';

import ScrollToTop from 'utils/ScrollToTop';

// Pages
// import Test from 'pages/Test/container';
import NotFound from 'pages/NotFound/page';

import PrivateRoute from 'components/PrivateRoute';
import Login from 'pages/Login/page';
import Home from 'pages/Home/container';
import Migrate from 'pages/Migrate/container';
import MediaLibrary from 'pages/MediaLibrary/container';

import TestSettingsContainer from 'pages/TestSettings/container';
import TestOverviewContainer from 'pages/TestOverview/container';
import CreateQuestionContainer from 'pages/CreateQuestion/container';
import PreviewQuestionsContainer from 'pages/PreviewQuestions/container';

import CourseSettingsContainer from 'pages/CourseSettings/container';
import CourseOverviewContainer from 'pages/CourseOverview/container';
import CreateCourseLessonsContainer from 'pages/CourseLessons/container';

import UserManagementContainer from 'pages/UserManagement/container';

// To disable scroll position see -> https://github.com/reach/router/issues/242
const Routes = () => (
  <Router className="main" primary={false}>
    <ScrollToTop path={ROUTE_PATHS.home}>
      <Login path={ROUTE_PATHS.login} />
      <PrivateRoute component={Home} path={ROUTE_PATHS.home} />
      <PrivateRoute component={MediaLibrary} path={ROUTE_PATHS.mediaLibrary} />

      <PrivateRoute component={TestOverviewContainer} path={ROUTE_PATHS.testDetails} />
      <PrivateRoute component={TestSettingsContainer} path={ROUTE_PATHS.testCreate} />
      <PrivateRoute component={TestSettingsContainer} path={ROUTE_PATHS.testSettings} />

      <PrivateRoute component={CreateQuestionContainer} path={ROUTE_PATHS.createQuestions} />
      <PrivateRoute component={PreviewQuestionsContainer} path={ROUTE_PATHS.previewQuestions} />

      <PrivateRoute component={CourseOverviewContainer} path={ROUTE_PATHS.courseDetails} />
      <PrivateRoute component={CourseSettingsContainer} path={ROUTE_PATHS.courseCreate} />
      <PrivateRoute component={CourseSettingsContainer} path={ROUTE_PATHS.courseSettings} />

      <PrivateRoute component={CreateCourseLessonsContainer} path={ROUTE_PATHS.courseLessons} />

      <PrivateRoute component={UserManagementContainer} path={ROUTE_PATHS.users} />

      <PrivateRoute component={Migrate} path={ROUTE_PATHS.migrate} />

      <NotFound default />
    </ScrollToTop>
  </Router>
);

export default Routes;
