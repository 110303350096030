/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { UnstyledLink } from 'components/Router';
import { omit } from 'lodash-es';
import { SortableHandle } from 'react-sortable-hoc';
// Components
import H4 from 'components/htmlElements/H4';
import A from 'components/htmlElements/A';
import Button from 'components/htmlElements/Button';
import Icon from 'components/Icon';

import TestSettingsChip from 'components/TestSettingsChip';
import settingsHelpers from 'utils/settingsHelpers';
import CreateTestContainer from 'hooks/useCreateTest';

// Constants
import { TEST_STRUCTURE, EXTRA_TIME_TYPES } from 'globals/constants';

// Styling
import { spacer } from 'styles/utilities';
import * as styles from './SectionSummaryBand.styles';

const { getSectionSettingsFormFields } = settingsHelpers;

const DragHandle = SortableHandle(() => (
  <div css={spacer.mrR10} className="drag-icon-wrapper">
    <Icon icon="drag" size="20" color="grey" />
  </div>
));

const SectionSummaryBand = ({
  onHandleClick,
  data,
  isSubSection,
  parentSectionId,
  hideAddQuestions,
  borderless,
  children
}) => {
  const { testSettings } = CreateTestContainer.useContainer();

  const [settingsList, updateSettingsList] = useState({});
  useEffect(() => {
    updateSettingsList(() => {
      let updatedState = getSectionSettingsFormFields({
        sectionSettings: {
          markingSettings: data.markingSettings,
          settings: data.settings
        },
        additionalFieldsToAdd: {
          ...(data.settings?.timeLimit && { timeLimit: data.settings.timeLimit }),
          ...(data.settings?.allowedExtraTime && {
            allowedExtraTime: data.settings.allowedExtraTime
          }),
          ...(data.settings?.allowedExtraTimeType &&
            data.settings?.allowedExtraTime && {
              allowedExtraTimeType:
                data.settings.allowedExtraTimeType === EXTRA_TIME_TYPES.percentage
                  ? data.settings.allowedExtraTimeType
                  : 'mins' // This is simply for displaying the value to user
            })
        }
      });

      // Omit `_id` as we don't need to show that
      updatedState = omit(updatedState, ['_id', 'name']);

      // Omit `defaultNegativeMarks` if `negativeMarkingEnabled` is false
      if (!updatedState.negativeMarkingEnabled.value) {
        updatedState = omit(updatedState, 'defaultNegativeMarks');
      }

      updatedState.totalQuestions = {
        text: 'Total Questions',
        value: true,
        positiveStatus: data.totalQuestions
      };
      updatedState.totalMarks = {
        text: 'Total Marks',
        value: true,
        positiveStatus: data.totalMarks
      };
      return updatedState;
    });
  }, [
    data.markingSettings,
    data.settings,
    data.settings.allowedExtraTime,
    data.settings.allowedExtraTimeType,
    data.settings.timeLimit,
    data.totalMarks,
    data.totalQuestions
  ]);

  const handleEditClick = (event) => {
    event.preventDefault();
    onHandleClick();
  };

  const getLinkToQuestions = (preview) => {
    let link = preview ? `preview` : `questions`;
    if (isSubSection) {
      link = `${link}?sectionId=${parentSectionId}&subsectionId=${data._id}`;
    } else {
      link = `${link}?sectionId=${data._id}`;
    }
    return link;
  };

  const getTestSettingsChipValue = (itemObj) => {
    if (itemObj.value) {
      return itemObj.positiveStatus;
    }
    return itemObj.negativeStatus;
  };

  if (!data) return null;

  return (
    <div css={styles.band} className={borderless ? 'borderless' : ''}>
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <DragHandle />
          <H4 workSans>{data.name}</H4>
        </div>
        <A href="#" semiBold onClick={handleEditClick}>
          Edit Details
        </A>
      </div>

      <div
        css={spacer.mrL30}
        className="d-flex align-items-center justify-content-between settings-content-wrapper"
      >
        <div className="d-flex flex-wrap">
          {Object.keys(settingsList).map((item) => (
            <div
              css={spacer.padR30}
              key={settingsList[item].text}
              className="setting-chip-item-wrapper"
            >
              <TestSettingsChip
                setting={settingsList[item].text}
                value={getTestSettingsChipValue(settingsList[item])}
              />
            </div>
          ))}
        </div>
      </div>

      {!hideAddQuestions && (
        <div className="d-flex align-items-center" css={styles.ctasWrapper}>
          <div>
            {((testSettings.questionStructure === TEST_STRUCTURE.subsections && isSubSection) ||
              testSettings.questionStructure === TEST_STRUCTURE.sections) && (
              <Button as={UnstyledLink} to={getLinkToQuestions()} className="flex-shrink-0">
                Add Questions
              </Button>
            )}
          </div>

          <div>
            {((testSettings.questionStructure === TEST_STRUCTURE.subsections && isSubSection) ||
              testSettings.questionStructure === TEST_STRUCTURE.sections) && (
              <A as={UnstyledLink} to={getLinkToQuestions(true)} semiBold>
                Preview questions
              </A>
            )}
          </div>
        </div>
      )}

      {children}
    </div>
  );
};

SectionSummaryBand.propTypes = {
  isSubSection: PropTypes.bool,
  onHandleClick: PropTypes.func,
  data: PropTypes.object.isRequired,
  children: PropTypes.node,
  parentSectionId: PropTypes.string,
  hideAddQuestions: PropTypes.bool,
  borderless: PropTypes.bool
};

SectionSummaryBand.defaultProps = {
  isSubSection: false,
  children: null,
  parentSectionId: undefined,
  hideAddQuestions: false,
  borderless: false,
  onHandleClick: null
};

export default SectionSummaryBand;
