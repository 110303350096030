/** @jsxImportSource @emotion/react */

import { Col, Row } from 'components/Grid/Grid';
import H2 from 'components/htmlElements/H2';
import PropTypes from 'prop-types';
import { spacer } from 'styles/utilities';
import Select from 'components/htmlElements/Fields/Select/Select';
import H3 from 'components/htmlElements/H3';
import { Redirect } from 'components/Router';
import { useState } from 'react';
import { UserInfoContainer } from 'api/auth';
import { EB_ORG_ID, ROUTE_PATHS } from 'globals/constants';
import TestContainer from './pageSections/TestContainer';

const Migrate = ({ tests }) => {
  const [selectedTestId, setSelectedTestId] = useState(null);
  const { orgId } = UserInfoContainer.useContainer();

  if (orgId !== EB_ORG_ID) {
    return <Redirect to={ROUTE_PATHS.home} noThrow />;
  }

  const selectHandler = ({ value }) => {
    setSelectedTestId(value);
  };

  const selectOptions = tests
    .filter((test) => test.meta?.ebQuestionsMigrated)
    .map((test) => ({ label: test.name, value: test._id }));

  return (
    <div css={[spacer.padT30, spacer.padLR40]}>
      <H2 as="h1" workSans semiBold className="mt-0" css={spacer.mrB40}>
        Migrate Tests
      </H2>

      <Row className="justify-content-between align-items-center" css={spacer.mrB40}>
        <Col css={spacer.mrB10} className="col-12 col-md-3">
          <H3 workSans semiBold className="m-0">
            Tests
          </H3>
        </Col>

        <Col css={spacer.mrB10} className="col-12 col-md-5">
          <Select
            onChange={selectHandler}
            placeholder="Select a test to migrate"
            options={selectOptions}
          />
        </Col>
      </Row>

      {selectedTestId ? (
        <TestContainer testId={selectedTestId} />
      ) : (
        <H3 workSans semiBold className="text-center" color="blackTransparent50" css={spacer.mrT80}>
          Select a test to migrate
        </H3>
      )}
    </div>
  );
};

Migrate.propTypes = {
  tests: PropTypes.array
};

Migrate.defaultProps = {
  tests: []
};

export default Migrate;
